<script setup lang="ts">
const props = defineProps({
  image: {
    type: Object as PropType<
      | {
          url: string
          width: number
          height: number
        }
      | null
      | undefined
    >,
    default: undefined,
    required: false
  },
  alt: {
    type: String,
    required: true
  },
  sizes: {
    type: String,
    default: 'sm:640px md:768px lg:1024px xl:1280px 2xl:1536px'
  },
  // This component is to change the aspectRatio by adjusting the height of the image
  cropToRatio: {
    type: Number,
    default: undefined
  },
  wrapperDimensions: {
    type: Object as PropType<{ width?: number; height?: number }>,
    default: undefined
  },
  lazy: {
    type: Boolean,
    default: true
  },
  imageClass: {
    type: String,
    default: ''
  },
  quality: {
    type: Number,
    default: 100
  }
})

let finalWidth = 0
let finalHeight = 0

if (props.image) {
  if (props.wrapperDimensions?.height && props.wrapperDimensions.width) {
    // Ignore the cropToRatio
    finalWidth = props.wrapperDimensions.width
    finalHeight = props.wrapperDimensions.height
  } else if (props.wrapperDimensions?.height && props.cropToRatio) {
    // We have a height constraint, but no width constraint
    finalHeight = props.wrapperDimensions.height
    finalWidth = Math.floor(props.cropToRatio * props.wrapperDimensions.height)
  } else if (props.wrapperDimensions?.width && props.cropToRatio) {
    // We have a width constraint, but no height constraint
    finalWidth = props.wrapperDimensions.width
    finalHeight = Math.floor(props.wrapperDimensions.width / props.cropToRatio)
  } else if (props.cropToRatio) {
    finalWidth = props.image.width
    finalHeight = Math.floor(props.image.width / props.cropToRatio)
  } else if (props.wrapperDimensions?.width) {
    finalWidth = props.wrapperDimensions.width
    const ratio = props.image.width / props.image.height
    finalHeight = Math.floor(props.wrapperDimensions.width / ratio)
  } else if (props.wrapperDimensions?.height) {
    finalWidth = props.image.width
    const ratio = props.image.width / props.image.height
    finalHeight = Math.floor(props.wrapperDimensions.height * ratio)
  } else {
    finalHeight = props.image.height
    finalWidth = props.image.width
  }
}
</script>

<template>
  <div>
    <nuxt-picture
      v-if="props.image"
      format="webp"
      :img-attrs="{
        class: `rounded-card w-full ${props.imageClass}`,
        fetchpriority: props.lazy ? 'low' : 'high',
        loading: props.lazy ? 'lazy' : 'eager',
        decoding: props.lazy ? 'async' : 'sync'
      }"
      :src="toS3AccelerateUrl(props.image.url)"
      :alt="props.alt"
      :modifiers="{
        position: 'top'
      }"
      fit="cover"
      :quality="props.quality"
      :width="finalWidth"
      :height="finalHeight"
      :sizes="props.sizes"
      :loading="props.lazy ? 'lazy' : 'eager'"
      :placeholder="1"
    />
  </div>
</template>
